<template>
    <div>
        <div>
            <Breadcrumb content="推荐卡组管理"/>
        </div>
        <div>
            <el-button-group>
                <el-button @click="refresh" type="primary" icon="el-icon-refresh-left" title="刷新"></el-button>
                <el-button type="danger" @click="dialogFormVisible=true">卡组维护账号管理</el-button>
                <el-button type="warning" @click="$router.push({path:'recommendDeckAdd',query:{type:2}})">新增推荐卡组</el-button>
                <el-button type="success" @click="$router.push({path:'recommendDeckAdd',query:{type:3}})">新增产品预组</el-button>
            </el-button-group>
        </div>
        <div>
            <el-dialog title="卡组维护账号管理" :visible.sync="dialogFormVisible" v-if="dialogFormVisible">
                <DeckUser></DeckUser>
            </el-dialog>
        </div>

        <div>
            <el-table
                    :data="formatData"
                    border
                    size="small"
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="edit_at"
                        label="编辑时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="typeName"
                        label="卡组类型"
                >
                </el-table-column>
                <el-table-column
                        prop="deck_id"
                        label="卡组ID"
                >
                </el-table-column>
                <el-table-column
                        prop="title"
                        label="标题"
                >
                </el-table-column>
                <el-table-column
                        prop="nav_title"
                        label="名称"
                >
                </el-table-column>
                <el-table-column width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="success"
                                @click="handleEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                                size="mini"
                                type="danger"
                                @click="handleDel(scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="padding:20px 0">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    import DeckUser from './components/deck-user'

    export default {
        name: "index",
        components:{Breadcrumb,DeckUser},
        data() {
            return {
                dialogFormVisible: false,
                pageSize:this.$webConfig.pages.pageSize,
                pageSizes:this.$webConfig.pages.pageSizes,
                currentPage:1,
                tableData: [],
                total:0,
            }
        },
        methods: {
            refresh() {
                this.getList();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getList();
            },
            getList:function () {
                let page = this.currentPage;
                let limit = this.pageSize;
                this.$axios.post("site/siteDeck/deckList",{page,limit})
                    .then((res) => {
                        if (res.data.code == 1000){
                            this.tableData = res.data.data.list;
                            this.total = res.data.data.total;
                        }
                    })
            },
            handleEdit(row){
                let deck_id = row.deck_id;
                this.$router.push({path:"/admin/recommendDeckEdit",query:{deck_id}})
            },
            handleDel(row){
                let deck_id = row.deck_id;
                this.$confirm("是否确认删除该条数据?").then(()=>{
                    this.$axios.post("site/siteDeck/del",{deck_id})
                        .then((res)=>{
                            if (res.data.code == 1000){
                                this.$message.success("删除成功");
                                this.getList()
                            }else{
                                this.$message.error(res.data.msg)
                            }
                        })
                });
            }
        },
        computed: {
            formatData() {
                let data = [];
                this.tableData.forEach((v)=>{
                    if (v.site_deck.type == 2){
                        v.typeName = "推荐卡组";
                    } else {
                        v.typeName = "产品预组";
                    }
                    data.push(v);
                });
                return data;
            }
        },
        created() {
            this.getList();
        }
    }
</script>
<style scoped>

</style>
