<template>
    <div>
        <el-form label-width="200px">
            <el-form-item label="官方推荐卡组维护人员BSID">
                <el-input v-model="form.recommend"></el-input>
            </el-form-item>
            <el-form-item label="产品预组维护人员BSID">
                <el-input v-model="form.product"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="success" @click="saveRecommendUser">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "deck-user",
        data() {
            return {
                form: {
                    recommend:0,
                    product:0
                }
            }
        },
        methods: {
            saveRecommendUser() {
                this.$axios.post("site/siteRecommendDeck/update",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$message.success("保存成功");
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            }
        },
        created() {
            this.$axios.post("site/siteRecommendDeck/info",{})
                .then((res)=>{
                    this.form.recommend = res.data.data.recommend;
                    this.form.product = res.data.data.product;
            })
        }
    }
</script>

<style scoped>

</style>
